import dic from './assets/dic.png'
import se from './assets/se.webp'
import te from './assets/te.jpg'
import tw from './assets/tw.png'

export const services = [
    {
        title: "Technology Enthusiast",
        icon: te,
    },
    {
        title: "Software Engineer",
        icon: se,
    },
    {
        title: "Technical Writers",
        icon: tw,
    },
];

export const name = 'HIMANSHU';

export const experiences = [
    {
        'company': 'Fabulous Media',
        'role': 'Software Development Intern',
        'duration': 'June 2022 - August 2022',
        'logo': 'https://content.app-sources.com/s/5059166616638299/uploads/Brand/digital-agency-2669171.png?format=webp',
        'points': [
            'Worked as a Frontend Web Developer, Proficiently producing user-friendly and responsive websites both collaboratively within a team and individually.',
            'Collaborated with the Frontend Development team to create and implement responsive websites like Health Upwardly Mobile, Halo Homes and Fabulous Media, that ensuring optimal user experience.',
            'Led impactful solo projects, merging innovative design with SEO strategies to elevate users engagement. These initiatives significantly contributed to a remarkable 56 percent increase in company revenue.'
        ],
        'url': 'https://fabulousmedia.in/',
    },
    {
        'company': 'MDaRT DIC Chandigarh',
        'role': 'ARTIFICIAL INTELLIGENCE',
        'duration': 'June 2023 - July 2023',
        'logo': dic,
        'points': [
            'Developed a skull reconstruction model using a 3D U-Net architecture, leveraging a comprehensive dataset sourced from the MUG500 repository consisting of NRRD images.',
            'Implemented advanced algorithms and 3D modeling techniques to achieve exceptional accuracy of 89% in restoring skull structures, resulting in enhanced surgical planning and patient outcomes',
            'Proficient in deep learning techniques, specializing in medical image analysis and data preprocessing, with a proven track record of exceptional performance',
        ],
        'url': 'https://dicpu.in/',
    },
    
    {
        'company': 'Inncircles Technologies',
        'role': 'Product Developer',
        'duration': 'July 2023 - April 2024',
        'logo': 'https://images.crunchbase.com/image/upload/c_pad,f_auto,q_auto:eco,dpr_1/e5gz1yggdy8sc1umna3e',
        'points': [
            'Involved in dynamic contributions to web and mobile📱 development projects, actively influencing and improving project outcomes.',
            'Developed multiple shared components to simplify tasks for fellow developers, boosting efficiency and teamwork.',
            'Enhanced product performance through significant contributions to backend development, optimizing essential components for improved efficiency 📈.',
            'Implemented data security for the company’s product to provide role-based access control ⚙️.'
        ],
        'url': 'https://inncircles.com/#home',
    },
   
    {
        'company': 'AT & T',
        'role': 'Technical Intern',
        'duration': 'June 2024 - July 2024',
        'logo': 'https://www.att.com/ecms/dam/att/consumer/global/logos/att_globe_500x500.jpg',
        'points': [
            'Gained experience with Git version control contributing to the development and maintenance of code repositories.',
            'Worked on the application dashboard, making it more user-friendly and improving its overall functionality📱.'
        ],
        'url': 'https://www.att.com/',
    }
]



// export const EMAIL_JS_SERVICE_ID = '';
// export const EMAIL_JS_TEMPLATE_ID = '';
// export const EMAIL_JS_PUBLIC_KEY = '';