import React from 'react';
import { FaGithub, FaTwitter,FaInstagram } from 'react-icons/fa';
import { AiFillLinkedin, } from 'react-icons/ai';
import { FiMail } from 'react-icons/fi';
import { SiLeetcode } from 'react-icons/si';


const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<div className='bg-[#161716] p-4' style={{ cursor: 'default' }}>
			<div className='flex justify-between flex-wrap gap-4'>
				
				<div className='text-[#dfdede] flex justify-around sm:w-[250px] w-full'>
					
				<a href="mailto:hybirar.103@gmail.com" className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
						<FiMail className='text-xl' />
					</a>
					<a href="https://www.linkedin.com/in/himanshu-yadav-5a10b6241/" rel='noreferrer' target='_blank' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
						<AiFillLinkedin className='text-xl' />
					</a>
					<a href="https://github.com/Himanshu-0104" rel='noreferrer' target='_blank' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }} >
						<FaGithub className='text-xl' />
					</a>
					<a href="https://leetcode.com/u/Himanshu0104/" rel='noreferrer' target='_blank' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
						<SiLeetcode className='text-xl' />
					</a>
					<a href="https://twitter.com/Himuyadav01" rel='noreferrer' target='_blank' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
						<FaTwitter className='text-xl' />
					</a>
                    <a href="https://www.instagram.com/himanshu.22v/" rel='noreferrer' target='_blank' className='transition ease-in-out duration-300 rounded-md hover:scale-110 cursor-pointer hover:-translate-y-1' style={{ cursor: 'pointer' }}>
						<FaInstagram className='text-xl' />
					</a>
				</div>
				<p className='text-[#dfdede] text-center w-full sm:w-auto font-light '>© {year} Himanshu Yadav. All rights reserved.</p>
			</div>
		</div>
	);
}

export default Footer;